//Fetch posts constant
export const FETCH_POST_STARTED = 'FETCH_POST_STARTED';
export const FETCH_POST_SUCCESSED = 'FETCH_POST_SUCCESSED';
export const LOAD_MORE_POST_SUCCESSED = 'LOAD_MORE_POST_SUCCESSED';
export const FETCH_POST_FAILED = 'FETCH_POST_FAILED';

//Create post constant
export const CREATE_POST_STARTED = 'CREATE_POST_STARTED';
export const CREATE_POST_SUCCESSED = 'CREATE_POST_SUCCESSED';
export const CREATE_POST_FAILED = 'CREATE_POST_FAILED';
