import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import authReducer from './reducers/authReducer';
import signUpReducer from './reducers/signUpReducer';
import postReducer from './reducers/postReducer';

const store = configureStore({
    // Automatically calls `combineReducers`
    reducer: {
        auth: authReducer,
        signUp: signUpReducer,
        post: postReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})

export default store