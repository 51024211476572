import { createSelector } from '@reduxjs/toolkit';

const selectAuthState = state => state;

export const selectAccessToken = createSelector(
    selectAuthState,
    postsState => {
        return postsState.auth.accessToken;
    }
);

export const selectRefreshToken = createSelector(
    selectAuthState,
    postsState => {
        return postsState.auth.refreshToken;
    }
);
export const selectStatusAuth = createSelector(
    selectAuthState,
    postsState => {
        return postsState.auth.isAuthenticated;
    }
);
export const selectCurrentUser = createSelector(
    selectAuthState,
    postsState => {
        return postsState.auth.user;
    }
);