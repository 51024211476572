import axios from 'axios';


// const localhost = 'http://localhost:3331';
const prod = 'https://socialnexapi.hieutdc.id.vn';

export const baseURL = axios.create({
    baseURL: `${prod}/api/v1`
});

