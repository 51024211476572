import './loadingPage.css';
import logo from '../../../assets/app/logo.png';


export default function LoadingPage() {
    return (
        <div className="loadingPage">
            <img src={logo} alt="" />
        </div>
    )
}