import React, { createContext, useState } from 'react';

const TabContext = createContext();

const MyProvider = ({ children }) => {
    const [tab, setTab] = useState(0);

    const updateTab = (newState) => {
        setTab(newState);
    };

    return (
        <TabContext.Provider value={{ tab, updateTab }}>
            {children}
        </TabContext.Provider>
    );
};
export { TabContext, MyProvider };