import './App.css';
import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { MyProvider } from './context/context';
import { LocationProvider } from './context/locationContext';
import LoadingPage from './views/pages/loadingPage/loadingPage';
import AuthRoute from './components/AuthRoute/AuthRoute';
const MainPage = lazy(() => import('./views/pages/mainPage/mainPage'));
const LoginPage = lazy(() => import('./views/pages/loginPage/loginPage'));
const ForgetPassword = lazy(() => import('./views/pages/forgetPassword/forgetPassword'));
const ProfilePage = lazy(() => import('./views/pages/profilePage/profilePage'));
const FriendPage = lazy(() => import('./views/pages/friendPage/friendPage'));
const FriendRequestPage = lazy(() => import('./views/pages/friendRequestPage/friendRequestPage'));
const FriendSuggestionPage = lazy(() => import('./views/pages/friendSuggestionPage/friendSuggestionPage'));
const FriendListPage = lazy(() => import('./views/pages/friendListPage/friendListPage'));
const NotFoundPage = lazy(() => import('./views/pages/notFoundPage/notFoundPage'));
const FriendBirthdayPage = lazy(() => import('./views/pages/friendBirthdayPage/friendBirthdayPage'));
const PhotoPage = lazy(() => import('./views/pages/photoPage/photoPage'));
const GroupPage = lazy(() => import('./views/pages/groupPage/groupPage'));
const Menu = lazy(() => import('./views/pages/responsive/menu/menu'));
const UserLayout = lazy(() => import('./views/layout/users/userLayout'));

function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <MyProvider>
        <LocationProvider>
          <Routes>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/login/forget-password' element={<ForgetPassword />} />
            <Route path="/" element={<AuthRoute children={<UserLayout children={<MainPage />} />} />} />
            <Route path="/home" element={<AuthRoute children={<UserLayout children={<MainPage />} />} />} />
            <Route path='/profile' element={<AuthRoute children={<UserLayout children={<ProfilePage />} />} />} />
            <Route path='/profile/:tab' element={<AuthRoute children={<UserLayout children={<ProfilePage />} />} />} />
            <Route path='/friends' element={<AuthRoute children={<UserLayout children={<FriendPage />} />} />} />
            <Route path='/friends/requests' element={<AuthRoute children={<UserLayout children={<FriendRequestPage />} />} />} />
            <Route path='/friends/requests/:parent/:page' element={<AuthRoute children={<UserLayout children={<FriendRequestPage />} />} />} />
            <Route path='/friends/suggestions' element={<AuthRoute children={<UserLayout children={<FriendSuggestionPage />} />} />} />
            <Route path='/friends/list' element={<AuthRoute children={<UserLayout children={<FriendListPage />} />} />} />
            <Route path='/friends/birthdays' element={<AuthRoute children={<UserLayout children={<FriendBirthdayPage />} />} />} />
            <Route path='/photo' element={<AuthRoute children={<UserLayout children={<PhotoPage />} />} />} />
            <Route path='/groups/:tab/:id' element={<AuthRoute children={<UserLayout children={<GroupPage />} />} />} />
            <Route path='/groups/:tab/:id/:sk' element={<AuthRoute children={<UserLayout children={<GroupPage />} />} />} />
            <Route path='/groups/:tab/:id/:sk/:parent' element={<AuthRoute children={<UserLayout children={<GroupPage />} />} />} />
            <Route path='/groups/:tab' element={<AuthRoute children={<UserLayout children={<GroupPage />} />} />} />
            <Route path='/menu' element={<AuthRoute children={<Menu />} />} />
            <Route path='/*' element={<UserLayout children={<NotFoundPage />} />} />
          </Routes>
        </LocationProvider>
      </MyProvider>
    </Suspense>
  );
}

export default App;
