import React, { createContext, useState } from 'react';

const LocationContext = createContext();

const LocationProvider = ({ children }) => {
    const [location, setLocation] = useState(0);

    const updateLocation = (newState) => {
        setLocation(newState);
    };

    return (
        <LocationContext.Provider value={{ location, updateLocation }}>
            {children}
        </LocationContext.Provider>
    );
};
export { LocationContext, LocationProvider };