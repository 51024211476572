//Sign in
export const SIGNINSTARTED = 'SIGNINSTARTED';
export const SIGNINSUCCESSED = 'SIGNINSUCCESSED';
export const SIGNINERROR = 'SIGNINERROR';
export const SIGNOUT = 'SIGNOUT';


//Refresh token
export const REFRESH_TOKEN_STARTED = "REFRESH_TOKEN_STARTED";
export const REFRESH_TOKEN_SUCCESSED = "REFRESH_TOKEN_SUCCESSED";
export const REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";