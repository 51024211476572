
import { SIGNINSUCCESSED, SIGNINERROR, SIGNOUT } from "../constants/authConstant";
import Cookies from 'js-cookie';

const initialState = {
    isAuthenticated: Cookies.get('accessToken') ? true : false,
    accessToken: Cookies.get('accessToken') ? Cookies.get('accessToken') : null,
    refreshToken: Cookies.get('refreshToken') ? Cookies.get('refreshToken') : null,
    user: Cookies.get('c_user') ? Cookies.get('c_user') : null,
    error: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGNINSUCCESSED:
            return {
                ...state,
                isAuthenticated: true,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                user: action.payload.user,
                error: null,
            };
        case SIGNINERROR:
            return {
                ...state,
                isAuthenticated: false,
                accessToken: null,
                refreshToken: null,
                user: null,
                error: action.error.message,
            };
        case SIGNOUT:
            return {
                ...state,
                isAuthenticated: false,
                accessToken: null,
                refreshToken: null,
                user: null,
                error: null,
            };
        default:
            return state;
    }
};

export default authReducer;