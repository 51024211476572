
import { SIGNUPSTARTED, SIGNUPSUCCESSED, SIGNUPERROR } from '../constants/signUpConstant';

const initialState = {
    status: 'loading',
    message: ''
};

const signUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGNUPSTARTED:
            return {
                ...state,
                status: 'loading',
                message: ''
            };
        case SIGNUPSUCCESSED:
            return {
                ...state,
                status: 'successed',
                message: action.payload
            };
        case SIGNUPERROR:
            return {
                ...state,
                status: 'error',
                message: action.payload
            };
        default:
            return state;
    }
};

export default signUpReducer;