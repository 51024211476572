
import {
    FETCH_POST_STARTED, FETCH_POST_SUCCESSED, FETCH_POST_FAILED, LOAD_MORE_POST_SUCCESSED,
    CREATE_POST_STARTED, CREATE_POST_SUCCESSED, CREATE_POST_FAILED
} from '../constants/postConstant';

const initialState = {
    status: 'loading',
    posts: [],
    skip: 0,
    limit: 15,
    error: null,
    statusCreate: null,
    statusDelete: null
};

const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POST_STARTED:
            return {
                ...state,
                status: 'fetching',
                posts: [],
                skip: 0,
                limit: 15,
                error: null,
                statusCreate: null,
                statusDelete: null
            };
        case FETCH_POST_SUCCESSED:
            return {
                ...state,
                status: 'fetched',
                posts: action.payload.posts,
                skip: action.payload.skip,
                limit: action.payload.limit,
                error: null,
                statusCreate: null,
                statusDelete: null
            };
        case LOAD_MORE_POST_SUCCESSED:
            return {
                ...state,
                status: 'fetched',
                posts: [...state.posts, ...action.payload.posts],
                skip: action.payload.skip,
                limit: action.payload.limit,
                error: null,
                statusCreate: null,
                statusDelete: null
            };
        case FETCH_POST_FAILED:
            return {
                ...state,
                status: 'error',
                error: action.payload,
                statusCreate: null,
                statusDelete: null,
            };
        case CREATE_POST_STARTED:
            return {
                ...state,
                status: 'fetched',
                posts: state.posts,
                skip: state.skip,
                limit: state.limit,
                error: null,
                statusCreate: 'creating',
                statusDelete: null
            };
        case CREATE_POST_SUCCESSED:
            return {
                ...state,
                status: 'fetched',
                posts: [action.payload, ...state.posts],
                skip: state.skip,
                limit: state.limit,
                error: null,
                statusCreate: 'created',
                statusDelete: null
            };
        case CREATE_POST_FAILED:
            return {
                ...state,
                status: 'fetched',
                posts: state.posts,
                skip: state.skip,
                limit: state.limit,
                error: action.payload,
                statusCreate: 'createFailed',
                statusDelete: null
            };
        default:
            return state;
    }
};

export default postReducer;