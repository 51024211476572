import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../app/appURL';
import { useSelector } from 'react-redux';
import {
    selectAccessToken,
} from '../../redux/selectors/authSelector';

export default function AuthRoute({ children }) {

    const navigate = useNavigate()

    const accessToken = useSelector(selectAccessToken);

    const [isLoggedIn, setIsLoggedIn] = useState();

    useEffect(() => {
        baseURL.get('/auth/verify-token', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then((res) => {
            if (res.status === 200) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        }).catch(e => { setIsLoggedIn(false); navigate('/login'); });
    }, [accessToken, navigate]);
    if (isLoggedIn) {
        return children;
    }
    return (
        <></>
    );
}